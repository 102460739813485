import { Action, ActionCreator } from 'redux';

import { PaperSize, PaperSizeData } from '~/utils/paperSize';
import {
  BaseSize,
  BillOfMaterialsData,
  CustomerApproval,
  DiscussionTopic,
  DiscussionTopicReply,
  Note,
  Point,
  Revision,
  TitleBlockData,
} from '~/store/reducers/wizard/State';

export enum WizardActionTypes {
  WIZARD_SET_ASSEMBLY_ID = 'WIZARD_SET_ASSEMBLY_ID',
  WIZARD_LOAD_ASSEMBLY_DATA = 'WIZARD_LOAD_ASSEMBLY_DATA',
  WIZARD_SET_BILL_OF_MATERIALS_DATA = 'WIZARD_SET_BILL_OF_MATERIALS_DATA',
  WIZARD_SET_BILL_OF_MATERIALS_POSITION = 'WIZARD_SET_BILL_OF_MATERIALS_POSITION',
  WIZARD_ADD_TABULATED_DATA = 'WIZARD_ADD_TABULATED_DATA',
  WIZARD_REMOVE_TABULATED_DATA = 'WIZARD_REMOVE_TABULATED_DATA',
  WIZARD_SET_TABULATED_DATA_POSITION = 'WIZARD_SET_TABULATED_DATA_POSITION',
  WIZARD_SET_REVISIONS_DATA = 'WIZARD_SET_REVISIONS_DATA',
  WIZARD_SET_CUSTOMER_APPROVAL_DATA = 'WIZARD_SET_CUSTOMER_APPROVAL_DATA',
  WIZARD_SET_REVISIONS_POSITION = 'WIZARD_SET_REVISIONS_POSITION',
  WIZARD_SET_CUSTOMER_APPROVALS_POSITION = 'WIZARD_SET_CUSTOMER_APPROVALS_POSITION',
  WIZARD_RESET_ASSEMBLY_DATA = 'WIZARD_RESET_ASSEMBLY_DATA',
  WIZARD_SET_DRAWING_NOTES_DATA = 'WIZARD_SET_DRAWING_NOTES_DATA',
  WIZARD_SET_DRAWING_NOTES_POSITION = 'WIZARD_SET_DRAWING_NOTES_POSITION',
  WIZARD_SET_TITLE_BLOCK_DATA = 'WIZARD_SET_TITLE_BLOCK_DATA',
  WIZARD_SET_TEMPLATE_SVG = 'WIZARD_SET_TEMPLATE_SVG',
  WIZARD_SET_TEMPLATE_LOGO = 'WIZARD_SET_TEMPLATE_LOGO',
  WIZARD_SET_TAGS = 'WIZARD_SET_TAGS',
  WIZARD_SET_PAPER_SIZE = 'WIZARD_SET_PAPER_SIZE',
  WIZARD_RESET_DRAWING_DATA = 'WIZARD_RESET_DRAWING_DATA',
  WIZARD_ADD_DISCUSSION_TOPIC = 'WIZARD_ADD_DISCUSSION_TOPIC',
  WIZARD_ADD_DISCUSSION_COMMENT = 'WIZARD_ADD_DISCUSSION_COMMENT',
  WIZARD_LOAD_DISCUSSIONS = 'WIZARD_LOAD_DISCUSSIONS',
  WIZARD_UPDATE_PAPER_SIZE_DATA = 'WIZARD_UPDATE_PAPER_SIZE_DATA',
  WIZARD_SET_NEW_TEMPLATE_POSITION = 'WIZARD_SET_NEW_TEMPLATE_POSITION',
  WIZARD_SET_NEW_TITLE_BLOCK_STYLE = 'WIZARD_SET_NEW_TITLE_BLOCK_STYLE',
  WIZARD_SET_OPEN_MODAL_EDIT_CONNECTOR_PIN = 'WIZARD_SET_OPEN_MODAL_EDIT_CONNECTOR_PIN',
  WIZARD_SET_LOGO_DIMENSION = 'WIZARD_SET_LOGO_DIMENSION',
  WIZARD_SET_IS_VIEW_ONLY = 'WIZARD_SET_IS_VIEW_ONLY',
  WIZARD_SET_IS_LOADING = 'WIZARD_SET_IS_LOADING',
  WIZARD_SET_DRAWING_NUMBER = 'WIZARD_SET_DRAWING_NUMBER',
  WIZARD_SET_DRAWING_REVISION = 'WIZARD_SET_DRAWING_REVISION',
  WIZARD_SET_CABLE_TEST_CARD_DATA = 'WIZARD_SET_CABLE_TEST_CARD_DATA',
  WIZARD_SET_REQUESTED_QUOTES_DATA = 'WIZARD_SET_REQUESTED_QUOTES_DATA',
  WIZARD_UPDATE_ASSEMBLY_STATUS = 'WIZARD_UPDATE_ASSEMBLY_STATUS',
  WIZARD_UPDATE_ASSEMBLY_Visibility = 'WIZARD_UPDATE_ASSEMBLY_Visibility',
  WIZARD_SET_APPROVAL_HISTORIES_TABLE_POSITION = 'WIZARD_SET_APPROVAL_HISTORIES_TABLE_POSITION',
  WIZARD_SET_MENU_VIEW_ACTIVE_LIST = 'WIZARD_SET_MENU_VIEW_ACTIVE_LIST',
  WIZARD_SET_ATTACHMENT_LIST = 'WIZARD_SET_ATTACHMENT_LIST',
}

export type WizardSetAssemblyID = {
  id: number;
} & Action<WizardActionTypes.WIZARD_SET_ASSEMBLY_ID>;

export const wizardSetAssemblyID: ActionCreator<WizardSetAssemblyID> = (id: number) => ({
  type: WizardActionTypes.WIZARD_SET_ASSEMBLY_ID,
  id,
});

export type WizardLoadAssemblyData = {
  assembly: any;
} & Action<WizardActionTypes.WIZARD_LOAD_ASSEMBLY_DATA>;

export const wizardLoadAssemblyData: ActionCreator<WizardLoadAssemblyData> = (assembly: any) => ({
  type: WizardActionTypes.WIZARD_LOAD_ASSEMBLY_DATA,
  assembly,
});

export type WizardSetBillOfMaterialsData = {
  billOfMaterialsData: BillOfMaterialsData[];
} & Action<WizardActionTypes.WIZARD_SET_BILL_OF_MATERIALS_DATA>;

export const wizardSetBillOfMaterialsData: ActionCreator<WizardSetBillOfMaterialsData> = (
  billOfMaterialsData: BillOfMaterialsData[],
) => ({
  type: WizardActionTypes.WIZARD_SET_BILL_OF_MATERIALS_DATA,
  billOfMaterialsData,
});

export type WizardSetBillOfMaterialsPosition = {
  position: Point;
} & Action<WizardActionTypes.WIZARD_SET_BILL_OF_MATERIALS_POSITION>;

export const wizardSetBillOfMaterialsPosition: ActionCreator<WizardSetBillOfMaterialsPosition> = (
  position: Point,
) => ({
  type: WizardActionTypes.WIZARD_SET_BILL_OF_MATERIALS_POSITION,
  position,
});

export type WizardAddTabulatedData = {
  tabulatedData: any;
} & Action<WizardActionTypes.WIZARD_ADD_TABULATED_DATA>;

export const wizardAddTabulatedData: ActionCreator<WizardAddTabulatedData> = (
  tabulatedData: any,
) => ({
  type: WizardActionTypes.WIZARD_ADD_TABULATED_DATA,
  tabulatedData,
});

export type WizardRemoveTabulatedData = {
  key: any;
} & Action<WizardActionTypes.WIZARD_REMOVE_TABULATED_DATA>;

export const wizardRemoveTabulatedData: ActionCreator<WizardRemoveTabulatedData> = (
  key: string,
) => ({
  type: WizardActionTypes.WIZARD_REMOVE_TABULATED_DATA,
  key,
});

export type WizardSetTabulatedDataPosition = {
  position: Point;
} & Action<WizardActionTypes.WIZARD_SET_TABULATED_DATA_POSITION>;

export const wizardSetTabulatedDataPosition: ActionCreator<WizardSetTabulatedDataPosition> = (
  position: Point,
) => ({
  type: WizardActionTypes.WIZARD_SET_TABULATED_DATA_POSITION,
  position,
});

export type WizardSetRevisionsData = {
  data: Revision[];
} & Action<WizardActionTypes.WIZARD_SET_REVISIONS_DATA>;

export const wizardSetRevisionsData: ActionCreator<WizardSetRevisionsData> = (
  data: Revision[],
) => ({
  type: WizardActionTypes.WIZARD_SET_REVISIONS_DATA,
  data,
});

export type WizardSetCustomerApprovalData = {
  data: CustomerApproval[];
} & Action<WizardActionTypes.WIZARD_SET_CUSTOMER_APPROVAL_DATA>;

export const wizardSetCustomerApprovalData: ActionCreator<WizardSetCustomerApprovalData> = (
  data: CustomerApproval[],
) => ({
  type: WizardActionTypes.WIZARD_SET_CUSTOMER_APPROVAL_DATA,
  data,
});

export type WizardSetRevisionsPosition = {
  position: Point;
} & Action<WizardActionTypes.WIZARD_SET_REVISIONS_POSITION>;

export const wizardSetRevisionsPosition: ActionCreator<WizardSetRevisionsPosition> = (
  position: Point,
) => ({
  type: WizardActionTypes.WIZARD_SET_REVISIONS_POSITION,
  position,
});

export type WizardSetCustomerApprovalsPosition = {
  position: Point;
} & Action<WizardActionTypes.WIZARD_SET_CUSTOMER_APPROVALS_POSITION>;

export const wizardSetCustomerApprovalsPosition: ActionCreator<
  WizardSetCustomerApprovalsPosition
> = (position: Point) => ({
  type: WizardActionTypes.WIZARD_SET_CUSTOMER_APPROVALS_POSITION,
  position,
});

export type WizardResetAssemblyData = Action<WizardActionTypes.WIZARD_RESET_ASSEMBLY_DATA>;

export const wizardResetAssemblyData: ActionCreator<WizardResetAssemblyData> = () => ({
  type: WizardActionTypes.WIZARD_RESET_ASSEMBLY_DATA,
});

export type WizardSetDrawingNotesData = {
  data: Note[];
} & Action<WizardActionTypes.WIZARD_SET_DRAWING_NOTES_DATA>;

export const wizardSetDrawingNotesData: ActionCreator<WizardSetDrawingNotesData> = (
  data: Note[],
) => ({
  type: WizardActionTypes.WIZARD_SET_DRAWING_NOTES_DATA,
  data,
});

export type WizardSetDrawingNotesPosition = {
  position: Point;
} & Action<WizardActionTypes.WIZARD_SET_DRAWING_NOTES_POSITION>;

export const wizardSetDrawingNotesPosition: ActionCreator<WizardSetDrawingNotesPosition> = (
  position: Point,
) => ({
  type: WizardActionTypes.WIZARD_SET_DRAWING_NOTES_POSITION,
  position,
});

export type WizardSetTitleBlockData = {
  data: TitleBlockData[];
} & Action<WizardActionTypes.WIZARD_SET_TITLE_BLOCK_DATA>;

export const wizardSetTitleBlockData: ActionCreator<WizardSetTitleBlockData> = (
  data: TitleBlockData[],
) => ({
  type: WizardActionTypes.WIZARD_SET_TITLE_BLOCK_DATA,
  data,
});

export type WizardSetTemplateSVG = {
  svg: string;
} & Action<WizardActionTypes.WIZARD_SET_TEMPLATE_SVG>;

export const wizardSetTemplateSVG: ActionCreator<WizardSetTemplateSVG> = (svg: string) => ({
  type: WizardActionTypes.WIZARD_SET_TEMPLATE_SVG,
  svg,
});

export type WizardSetTemplateLogo = {
  logo: string;
} & Action<WizardActionTypes.WIZARD_SET_TEMPLATE_LOGO>;

export const wizardSetTemplateLogo: ActionCreator<WizardSetTemplateLogo> = (logo: string) => ({
  type: WizardActionTypes.WIZARD_SET_TEMPLATE_LOGO,
  logo,
});

export type WizardSetTags = {
  tags: number[];
} & Action<WizardActionTypes.WIZARD_SET_TAGS>;

export const wizardSetTags: ActionCreator<WizardSetTags> = (tags: number[]) => ({
  type: WizardActionTypes.WIZARD_SET_TAGS,
  tags,
});

export type WizardSetPaperSize = {
  paperSize: string;
} & Action<WizardActionTypes.WIZARD_SET_PAPER_SIZE>;

export const wizardSetPaperSize: ActionCreator<WizardSetPaperSize> = (paperSize: string) => ({
  type: WizardActionTypes.WIZARD_SET_PAPER_SIZE,
  paperSize,
});

export type WizardResetDrawingData = Action<WizardActionTypes.WIZARD_RESET_DRAWING_DATA>;

export const wizardResetDrawingData: ActionCreator<WizardResetDrawingData> = () => ({
  type: WizardActionTypes.WIZARD_RESET_DRAWING_DATA,
});

export type WizardAddDiscussionTopic = {
  topic: DiscussionTopic;
} & Action<WizardActionTypes.WIZARD_ADD_DISCUSSION_TOPIC>;

export const wizardAddDiscussionTopic = (topic: DiscussionTopic) => ({
  type: WizardActionTypes.WIZARD_ADD_DISCUSSION_TOPIC,
  topic,
});

export type WizardAddDiscussionComment = {
  topicId: string;
  reply: DiscussionTopicReply;
} & Action<WizardActionTypes.WIZARD_ADD_DISCUSSION_COMMENT>;

export const wizardAddDiscussionComment = (topicId: string, reply: DiscussionTopicReply) => ({
  type: WizardActionTypes.WIZARD_ADD_DISCUSSION_COMMENT,
  topicId,
  reply,
});

export type WizardLoadDiscussions = {
  discussions: DiscussionTopic[];
} & Action<WizardActionTypes.WIZARD_LOAD_DISCUSSIONS>;

export const wizardLoadDiscussions = (discussions: DiscussionTopic[]) => ({
  type: WizardActionTypes.WIZARD_LOAD_DISCUSSIONS,
  discussions,
});

export type WizardUpdatePaperSizeData = {
  paperSizeData: PaperSizeData;
} & Action<WizardActionTypes.WIZARD_UPDATE_PAPER_SIZE_DATA>;

export const wizardUpdatePaperSizeData: ActionCreator<WizardUpdatePaperSizeData> = (
  paperSizeData: PaperSizeData,
) => ({
  type: WizardActionTypes.WIZARD_UPDATE_PAPER_SIZE_DATA,
  paperSizeData,
});

export type WizardSetNewTemplatePosition = {
  newTemplate: PaperSize;
} & Action<WizardActionTypes.WIZARD_SET_NEW_TEMPLATE_POSITION>;

export const wizardSetNewTemplatePosition: ActionCreator<WizardSetNewTemplatePosition> = (
  newTemplate: PaperSize,
) => ({
  type: WizardActionTypes.WIZARD_SET_NEW_TEMPLATE_POSITION,
  newTemplate,
});

export type WizardSetNewTitleBlockStyle = {
  newTitleBlockStyle: TitleBlockData[];
} & Action<WizardActionTypes.WIZARD_SET_NEW_TITLE_BLOCK_STYLE>;

export const wizardSetNewTitleBlockStyle: ActionCreator<WizardSetNewTitleBlockStyle> = (
  newTitleBlockStyle: TitleBlockData[],
) => ({
  type: WizardActionTypes.WIZARD_SET_NEW_TITLE_BLOCK_STYLE,
  newTitleBlockStyle,
});

export type WizardSetOpenModalEditConnectorPin = {
  openModalEditConnectorPin: boolean;
} & Action<WizardActionTypes.WIZARD_SET_OPEN_MODAL_EDIT_CONNECTOR_PIN>;

export const wizardSetOpenModalEditConnectorPin: ActionCreator<
  WizardSetOpenModalEditConnectorPin
> = (openModalEditConnectorPin: boolean) => ({
  type: WizardActionTypes.WIZARD_SET_OPEN_MODAL_EDIT_CONNECTOR_PIN,
  openModalEditConnectorPin,
});

export type WizardSetLogoDimension = {
  logoDimension: BaseSize;
} & Action<WizardActionTypes.WIZARD_SET_LOGO_DIMENSION>;

export const wizardSetLogoDimesion: ActionCreator<WizardSetLogoDimension> = (
  logoDimension: BaseSize,
) => ({
  type: WizardActionTypes.WIZARD_SET_LOGO_DIMENSION,
  logoDimension,
});

export type WizardSetIsViewOnly = {
  isViewOnly: boolean;
} & Action<WizardActionTypes.WIZARD_SET_IS_VIEW_ONLY>;

export const wizardSetIsViewOnly: ActionCreator<WizardSetIsViewOnly> = (isViewOnly: boolean) => ({
  type: WizardActionTypes.WIZARD_SET_IS_VIEW_ONLY,
  isViewOnly,
});

export type WizardSetIsLoading = {
  isLoading: boolean;
} & Action<WizardActionTypes.WIZARD_SET_IS_LOADING>;

export const wizardSetIsLoading: ActionCreator<WizardSetIsLoading> = (isLoading: boolean) => ({
  type: WizardActionTypes.WIZARD_SET_IS_LOADING,
  isLoading,
});

export type WizardSetDrawingNumber = {
  drawingNumber: string;
} & Action<WizardActionTypes.WIZARD_SET_DRAWING_NUMBER>;

export const wizardSetDrawingNumber: ActionCreator<WizardSetDrawingNumber> = (
  drawingNumber: string,
) => ({
  type: WizardActionTypes.WIZARD_SET_DRAWING_NUMBER,
  drawingNumber,
});

export type WizardSetDrawingRevision = {
  drawingRevision: string;
} & Action<WizardActionTypes.WIZARD_SET_DRAWING_REVISION>;

export const wizardSetDrawingRevision: ActionCreator<WizardSetDrawingRevision> = (
  drawingRevision: string,
) => ({
  type: WizardActionTypes.WIZARD_SET_DRAWING_REVISION,
  drawingRevision,
});

export type WizardSetCableTestCardData = {
  data: any;
} & Action<WizardActionTypes.WIZARD_SET_CABLE_TEST_CARD_DATA>;

export const wizardSetCableTestCardData: ActionCreator<WizardSetCableTestCardData> = (
  data: any,
) => ({
  type: WizardActionTypes.WIZARD_SET_CABLE_TEST_CARD_DATA,
  data,
});

export type WizardSetRequestedQuotesData = {
  data: any;
} & Action<WizardActionTypes.WIZARD_SET_REQUESTED_QUOTES_DATA>;

export const wizardSetRequestedQuotesData: ActionCreator<WizardSetRequestedQuotesData> = (
  data: any,
) => ({
  type: WizardActionTypes.WIZARD_SET_REQUESTED_QUOTES_DATA,
  data,
});

export type WizardUpdateAssemblyStatus = {
  status: string;
} & Action<WizardActionTypes.WIZARD_UPDATE_ASSEMBLY_STATUS>;

export const wizardUpdateAssemblyStatus: ActionCreator<WizardUpdateAssemblyStatus> = (
  status: string,
) => ({
  type: WizardActionTypes.WIZARD_UPDATE_ASSEMBLY_STATUS,
  status,
});

export type WizardUpdateAssemblyVisibility = {
  isPublic: boolean;
} & Action<WizardActionTypes.WIZARD_UPDATE_ASSEMBLY_Visibility>;

export const wizardUpdateAssemblyVisibility: ActionCreator<WizardUpdateAssemblyVisibility> = (
  isPublic: boolean,
) => ({
  type: WizardActionTypes.WIZARD_UPDATE_ASSEMBLY_Visibility,
  isPublic,
});

export type WizardSetApprovalHistoriesTablePosition = {
  position: Point;
} & Action<WizardActionTypes.WIZARD_SET_APPROVAL_HISTORIES_TABLE_POSITION>;

export const wizardSetApprovalHistoriesTablePosition: ActionCreator<
  WizardSetApprovalHistoriesTablePosition
> = (position: Point) => ({
  type: WizardActionTypes.WIZARD_SET_APPROVAL_HISTORIES_TABLE_POSITION,
  position,
});

export type WizardSetMenuViewActiveList = {
  data: string[];
} & Action<WizardActionTypes.WIZARD_SET_MENU_VIEW_ACTIVE_LIST>;

export const wizardSetMenuViewActiveList: ActionCreator<WizardSetMenuViewActiveList> = (
  data: string[],
) => ({
  type: WizardActionTypes.WIZARD_SET_MENU_VIEW_ACTIVE_LIST,
  data,
});
export type WizardSetAttachmentList = {
  data: any[];
} & Action<WizardActionTypes.WIZARD_SET_ATTACHMENT_LIST>;

export const wizardSetAttachmentList: ActionCreator<WizardSetAttachmentList> = (data: any[]) => ({
  type: WizardActionTypes.WIZARD_SET_ATTACHMENT_LIST,
  data,
});
