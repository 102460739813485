/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { RcFile } from 'antd/es/upload';
import Axios, { AxiosError } from 'axios';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { store } from '~/store';
import { AppState } from '~/store/reducers';

import {
  ADD_BOOKMARK_PART_ITEM,
  CHANGE_PASSWORD,
  DISABLE_USER,
  EDIT_PART_ITEM,
  UPDATE_ASSEMBLY_NO_REDIRECT,
  UPDATE_VISIBILITY,
  UPDATE_CONNECTOR,
  UPDATE_QUOTE_RESPONSE,
  UPDATE_REQUEST_REVIEW,
  UPDATE_TEMPLATE_SETTINGS,
  UPDATE_TITLE_BLOCK_SCHEMA,
  UPDATE_TRIAL_DATE,
  UPDATE_USER_PAYMENT,
  UPDATE_ASSEMBLY_UPLOAD,
} from '../constants';
import { makeActionCreator } from '../store/actions';
import { baseApiUrl } from '../utils/env';

import { UPDATE_USER, UPDATE_SUBSCRIPTION, ADD_ADMIN_ACCESS } from './../constants/index';
import {
  defaultErrorHandler,
  ErrorHandler,
  handleAPIError,
  handleResponseDataNotJson,
} from './HttpError';

const putApiGenerator =
  (route: string, name: string, successRedirect?: string, multipart?: boolean) =>
  (id: string, data?: any, errorHandler: ErrorHandler = defaultErrorHandler) => {
    const dispatch = store.dispatch as ThunkDispatch<AppState, void, AnyAction>;
    const getState = store.getState as () => AppState;

    const token = getState().data.auth.token;

    // dispatch request action
    dispatch(makeActionCreator(`${name}_REQUEST`)());

    if (id === undefined) {
      dispatch(
        makeActionCreator(
          `${name}_FAILURE`,
          'status',
          'statusText',
        )(400, 'No id specified for update.'),
      );

      return Promise.reject('No id specified for update.');
    }

    const dataLayer: any[] = [];

    // prepare put request
    let config = {};

    if (multipart) {
      config = {
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'multipart/form-data',
        },
      };
    } else {
      config = {
        headers: { Authorization: 'Bearer ' + token },
      };
    }

    let updatedRoute = baseApiUrl + route;

    if (id !== undefined && id !== '') {
      updatedRoute += '/' + id;
    }

    // const start = +new Date();
    return Axios.put(updatedRoute, data, config).then(
      (response) => {
        const responseData = response.data;

        if (responseData instanceof Object) {
          dispatch(makeActionCreator(`${name}_SUCCESS`, 'id')(id));

          // redirect on successful update
          if (successRedirect) {
            if (id && name !== UPDATE_CONNECTOR) {
              setTimeout(() => window.location.replace(successRedirect + '/' + id), 500);
            } else {
              setTimeout(() => window.location.replace(successRedirect), 500);
            }
          }
        } else {
          handleResponseDataNotJson(dispatch, name, 'PUT', dataLayer, route, errorHandler);
          // response data is not json
        }

        return responseData;
      },
      (error: AxiosError) => {
        handleAPIError(errorHandler, dispatch, error.response!, 'PUT', dataLayer, route, name);
        throw error;
      },
    );
  };

export const putUpdateUser = (data: any, logo: any, template: any) => {
  const formData = new FormData();
  formData.append(
    'json',
    JSON.stringify({
      ...data,
    }),
  );

  if (logo) {
    formData.append(logo.id, logo.data);
  }

  if (template) {
    formData.append(template.id, template.data);
  }

  return putApiGenerator('/_a/user', UPDATE_USER, undefined, true)('', formData);
};

export const putChangeVisibility = putApiGenerator('/_a/v2/assemblies', UPDATE_VISIBILITY);

export const putChangePassword = putApiGenerator(
  '/_a/user/password',
  CHANGE_PASSWORD,
  '/user/profile',
);

export const putUpdateSubscription = putApiGenerator('/_a/user_subscription', UPDATE_SUBSCRIPTION);

export const putGrantAdmin = putApiGenerator('/_a/user_access/admin', ADD_ADMIN_ACCESS);

export const putDisableUser = putApiGenerator('/_a/user_account/disable', DISABLE_USER);

export const putUpdatePartItem = (
  partItemId: string,
  partName: string,
  data: any,
  files: any[],
) => {
  const formData = new FormData();
  formData.append(
    'json',
    JSON.stringify({
      type: partName,
      ...data,
    }),
  );

  if (files && files.length) {
    files.forEach((file) => formData.append(file.id, file.data));
  }

  return putApiGenerator(`/_a/part`, EDIT_PART_ITEM, undefined, true)(partItemId, formData);
};

export const putAddBookmarkPartItem = putApiGenerator('/_a/parts/favorite', ADD_BOOKMARK_PART_ITEM);
export const putUpdateAssemblyNoRedirect = putApiGenerator(
  '/_a/v2/assemblies',
  UPDATE_ASSEMBLY_NO_REDIRECT,
);

export const putCreateDiscussionTopic = putApiGenerator(
  '/_a/v2/assemblies',
  UPDATE_ASSEMBLY_NO_REDIRECT,
);

export const putUpdateTemplateSettings = putApiGenerator(
  '/_a/user/template-settings',
  UPDATE_TEMPLATE_SETTINGS,
);

export const putUpdateTitleBlockSchema = putApiGenerator(
  '/_a/user/title-block-schema',
  UPDATE_TITLE_BLOCK_SCHEMA,
);

export const putUpdateTrialDate = putApiGenerator('/_a/update_trial_date/admin', UPDATE_TRIAL_DATE);

export const putUpdateUserRoles = putApiGenerator('/_a/user-roles', UPDATE_USER);

export const putUpdateQuoteResponse = (id: string, data: any, quoteDocument: RcFile) => {
  const formData = new FormData();
  formData.append(
    'json',
    JSON.stringify({
      ...data,
    }),
  );

  if (quoteDocument) {
    formData.append('quote_document', quoteDocument);
  }

  return putApiGenerator(
    '/_a/quote/response',
    UPDATE_QUOTE_RESPONSE,
    undefined,
    true,
  )(id, formData);
};

export const putUpdateUserPayment = putApiGenerator('/_a/user-payment', UPDATE_USER_PAYMENT);

export const putUpdateRequestReview = putApiGenerator('/_a/v2/assemblies', UPDATE_REQUEST_REVIEW);

export const putAssemblyUpload = (
  assemblyId: string,
  files: File[],
  errorHandler: ErrorHandler = defaultErrorHandler,
) => {
  const dispatch = store.dispatch as ThunkDispatch<AppState, void, AnyAction>;
  const getState = store.getState as () => AppState;

  const token = getState().data.auth.token;

  if (!assemblyId) {
    const errorMessage = 'Assembly ID is required.';

    return Promise.reject(errorMessage);
  }

  const formData = new FormData();

  files.forEach((file) => {
    formData.append('_fileName', file);
  });

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'multipart/form-data',
    },
  };

  const url = `/_a/v2/assemblies/${assemblyId}/upload`;

  dispatch(makeActionCreator(`${UPDATE_ASSEMBLY_UPLOAD}_REQUEST`)());

  return Axios.put(url, formData, config)
    .then((response) => {
      dispatch(makeActionCreator(`${UPDATE_ASSEMBLY_UPLOAD}_SUCCESS`)(response.data));
      return response.data;
    })
    .catch((error: AxiosError) => {
      handleAPIError(
        errorHandler,
        dispatch,
        error.response!,
        'PUT',
        [],
        url,
        UPDATE_ASSEMBLY_UPLOAD,
      );
      throw error;
    });
};
