import { Modal, Spin, Tabs, TabsProps } from 'antd';
import { useCallback, useEffect, useRef, useState } from 'react';
import { mxCell, mxGraph as mxGraphType } from '@anekonnect/mxgraph';
import { capitalize } from 'lodash';
import { useLocation, useParams } from 'react-router-dom';

import WizardEngineeringDrawing from './components/WizardEngineeringDrawing';
import WizardHeader from './components/WizardHeader/WizardHeader';
import styles from './Wizard.module.scss';
import { ActiveTabKey } from './components/MxGraph/Graph/Types';
import WizardBOM from './components/WizardBOM';
import WizardRevisions from './components/WizardRevisions';
import WizardDwgNotes from './components/WizardDwgNotes';
import WizardTabulatedData from './components/WizardTabulatedData';
import WizardTitleBlock from './components/WizardTitleBlock';
import { loadGlobalSetting, setGraphSetting } from './components/MxGraph/Graph/SetSettings';
import setGraphStyles from './components/MxGraph/Graph/SetStyles';
import WizardDiscussion from './components/WizardDiscussion';
import setWireStyles from './components/MxGraph/Schematics/Wire/Styles';
import { EdgeRef } from './components/MxGraph/Schematics/Wire/Types';
import setWireConnections from './components/MxGraph/Schematics/Wire/Connections';
import setWireSettings from './components/MxGraph/Schematics/Wire/Settings';
import WizardCableTestCard from './components/WizardCableTestCard';
import { LeftSidebar, RightSidebar } from './components/MxGraph/Sidebars';

import { getDataAssembliesItem, getUser, getWhoami } from '~/api/AuthorizedGets';
import { mx } from '~/constants/wizard';
import {
  wizardAddTabulatedData,
  wizardLoadAssemblyData,
  wizardLoadDiscussions,
  wizardSetApprovalHistoriesTablePosition,
  wizardSetBillOfMaterialsData,
  wizardSetBillOfMaterialsPosition,
  wizardSetCableTestCardData,
  wizardSetDrawingNotesData,
  wizardSetDrawingNotesPosition,
  wizardSetDrawingNumber,
  wizardSetDrawingRevision,
  wizardSetIsViewOnly,
  wizardSetMenuViewActiveList,
  wizardSetPaperSize,
  wizardSetRequestedQuotesData,
  wizardSetRevisionsData,
  wizardSetRevisionsPosition,
  wizardSetCustomerApprovalData,
  wizardSetCustomerApprovalsPosition,
  wizardSetTabulatedDataPosition,
  wizardSetTags,
  wizardSetTitleBlockData,
  wizardUpdatePaperSizeData,
  wizardSetAttachmentList,
} from '~/store/actions/wizard/Action';
import { isStateLoading } from '~/utils/state';
import {
  wizardLoadHiddenComponents,
  wizardSetComponentsData,
  wizardSetDBComponentsData,
} from '~/store/actions/wizard/Component';
import { isJson } from '~/utils/helper';
import { RequestQuote } from '~/pages/vendors';
import { rectangleLabelFixer } from './BackwardCompatibleHelper';
import WizardApprovalHistory from './components/WizardApprovalHistory';
import { useAppDispatch, useAppSelector } from '~/store/hooks';
import WizardSharedWith from './components/WizardSharedwith';

const { mxGraph } = mx;
const mxGraphValue = new mxGraph(null as unknown as HTMLElement);

type AssembliesItem = {
  id: string;
  name: string;
  details: string;
  components: string;
  drawing_notes_table: string;
  bill_of_materials_table: string;
  tabulated_data_table: string;
  revisions_table: string;
  customer_approvals_table: string;
  title_block_data: string;
  tags: number[];
  hidden_components: string;
  discussions: string;
  paper_size: string;
  created_by: number;
  created_by_guest: boolean;
  can_read: null | number[];
  can_write: null | number[];
  user_drawing_number: string;
  user_drawing_revision: string;
  cable_test_card_data: string;
  quotes: any[];
  approval_histories_table: string;
  menu_view_active_list: string;
  attchfile: any[];
};

const Wizard = () => {
  const dispatch = useAppDispatch();
  const { action, assemblyWizard, ui, data } = useAppSelector((state) => state);
  const isLoading =
    isStateLoading(
      action,
      'saveDataAssembly',
      'updateAssemblyNoRedirect',
      'postCopyAssembly',
      'postAsBuiltAssembly',
      'deleteAssemblyDiscussion',
      'postExportSVG',
      'putUpdateTemplateSettings',
      'putUpdateTitleBlockSchema',
    ) || assemblyWizard.isLoading;
  const { sideMenuBar } = ui;
  const isTenant = data.tenantConfig.isTenant;

  const route = useLocation();
  const params = useParams();
  const itemId = params.itemId;
  const [isLoadData, setIsLoadData] = useState(false);

  const [activeTabKey, setActiveTabKey] = useState<ActiveTabKey>('engineeringDrawing');
  const [graph, setGraph] = useState<mxGraphType>(mxGraphValue);
  const edgeRef = useRef<EdgeRef>({
    currentEdge: null,
    points: null,
    isEdgeSelected: false,
    groupingEdges: [],
  });

  const [isEditAction, setIsActionEdit] = useState(false);

  const handleTabChange = useCallback((activeKey: string) => {
    const newActiveKey = activeKey
      .split('_')
      .map((v, i) => (i > 0 ? capitalize(v) : v.toLowerCase()))
      .join('');

    setActiveTabKey(newActiveKey as ActiveTabKey);
  }, []);

  useEffect(() => setIsActionEdit(route.pathname.includes('edit')), [route]);

  const renderAssembliesData = useCallback(() => {
    const setupAssemblyData = async () => {
      setIsLoadData(true);

      const setReduxData = (data: AssembliesItem) => {
        if (data.components) {
          const components = JSON.parse(data.components);
          dispatch(wizardSetDBComponentsData(components));
          dispatch(wizardSetComponentsData(components));
        }

        if (data.paper_size) {
          dispatch(wizardSetPaperSize(data.paper_size));
        }

        if (data.drawing_notes_table) {
          const { position, data: drawingNotesData } = JSON.parse(data.drawing_notes_table);
          dispatch(wizardSetDrawingNotesData(drawingNotesData));
          dispatch(wizardSetDrawingNotesPosition(position));
        }

        if (data.bill_of_materials_table) {
          const { position, data: bomData } = JSON.parse(data.bill_of_materials_table);
          dispatch(wizardSetBillOfMaterialsData(bomData));
          dispatch(wizardSetBillOfMaterialsPosition(position));
        }

        if (data.tabulated_data_table) {
          const tabulatedData = JSON.parse(data.tabulated_data_table);
          dispatch(wizardAddTabulatedData(tabulatedData));
          dispatch(wizardSetTabulatedDataPosition(tabulatedData.position));
        }

        if (data.revisions_table) {
          const revisions = JSON.parse(data.revisions_table);
          dispatch(wizardSetRevisionsData(revisions.data));
          dispatch(wizardSetRevisionsPosition(revisions.position));
        }

        if (data.customer_approvals_table) {
          const customerApprovals = JSON.parse(data.customer_approvals_table);
          dispatch(wizardSetCustomerApprovalData(customerApprovals.data));
          dispatch(wizardSetCustomerApprovalsPosition(customerApprovals.position));
        }

        if (data.user_drawing_number) {
          dispatch(wizardSetDrawingNumber(data.user_drawing_number));
        }

        if (data.user_drawing_revision) {
          dispatch(wizardSetDrawingRevision(data.user_drawing_revision));
        }

        if (data.title_block_data) {
          const titleBlockData = JSON.parse(data.title_block_data);

          // Checking for new title block data schema
          if (Array.isArray(titleBlockData)) {
            dispatch(wizardSetTitleBlockData(titleBlockData));
          }
        }

        if (data.tags && data.tags.length > 0) {
          dispatch(wizardSetTags(data.tags));
        }

        if (data.hidden_components) {
          dispatch(wizardLoadHiddenComponents(JSON.parse(data.hidden_components)));
        }

        if (data.discussions) {
          const discussions = JSON.parse(data.discussions);
          dispatch(wizardLoadDiscussions(discussions));
        }

        if (data.cable_test_card_data) {
          const cableTestCardData = JSON.parse(data.cable_test_card_data);
          dispatch(wizardSetCableTestCardData(cableTestCardData));
        }

        if (data.approval_histories_table) {
          const { position } = JSON.parse(data.approval_histories_table);
          dispatch(wizardSetApprovalHistoriesTablePosition(position));
        }

        if (data.menu_view_active_list) {
          const activeList = data.menu_view_active_list.split(',');
          dispatch(wizardSetMenuViewActiveList(activeList));
        }
        if (data?.attchfile) {
          const attachfiles = data.attchfile;
          dispatch(wizardSetAttachmentList(attachfiles));
        }

        dispatch(wizardLoadAssemblyData(data));
      };

      const data: AssembliesItem = await getDataAssembliesItem(itemId);

      if (!data.created_by_guest) {
        const userData: {
          id: number;
          template_settings: string;
          title_block_schema: string;
          default_paper_size: string;
        } = await getWhoami();

        if (userData.template_settings) {
          dispatch(wizardUpdatePaperSizeData(JSON.parse(userData.template_settings)));
        }

        if (userData.title_block_schema) {
          dispatch(wizardSetTitleBlockData(JSON.parse(userData.title_block_schema)));
        }

        if (userData.default_paper_size) {
          dispatch(wizardSetPaperSize(userData.default_paper_size.toLowerCase()));
        }

        if (data.created_by !== userData.id) {
          const createdBy = String(data.created_by);
          const user = await getUser(createdBy);

          if (user.template_settings) {
            dispatch(wizardUpdatePaperSizeData(JSON.parse(user.template_settings)));
          }

          if (user.title_block_schema) {
            dispatch(wizardSetTitleBlockData(JSON.parse(user.title_block_schema)));
          }
        }

        if (data.can_read && data.can_read.includes(userData.id)) {
          dispatch(wizardSetIsViewOnly(true));
        }

        if (data.can_write && data.can_write.includes(userData.id)) {
          dispatch(wizardSetIsViewOnly(false));
        }

        if (data.quotes) {
          dispatch(wizardSetRequestedQuotesData(data.quotes));
        }
      }

      if (data && data.details) {
        const isDetailsJson = isJson(data.details);

        const modalWarning = () => {
          Modal.warning({
            title: 'The data cannot be loaded, due to the incorrect format',
            content: (
              <div>
                The drawing will be reset, please save it again after making changes. <br /> <br />
                Or you can request to support team to investigate the issue{' '}
                <a
                  href={`mailto:support@anekonnect.io?subject=Drawing cannot be loaded for assembly id ${data.id}&body=The drawing cannot be loaded, due to the incorrect format. Please check the data for assembly id ${data.id}`}
                >
                  please click here
                </a>
                .{' '}
              </div>
            ),
          });
        };

        if (!isDetailsJson) {
          modalWarning();
          setIsLoadData(false);

          return;
        }

        const details = JSON.parse(data.details);

        setGraphSetting(mx, graph, data.paper_size || 'a4');
        loadGlobalSetting(mx, graph);
        setGraphStyles(mx, graph);
        setWireConnections(mx, graph, edgeRef);
        setWireSettings(mx, graph);
        setWireStyles(mx, graph);

        graph.model.clear();
        graph.getModel().beginUpdate();

        try {
          const doc = mx.mxUtils.parseXml(details['engineeringDrawing']);
          const codec = new mx.mxCodec(doc);
          const model = graph.getModel();
          const result = codec.decode(doc.documentElement, model);
          const cells = Object.values(result.cells) as mxCell[];

          cells.forEach((cell) => {
            rectangleLabelFixer(cell);
          });
        } catch (err) {
          console.log(err);
          modalWarning();
        } finally {
          graph.getModel().endUpdate();
        }
      }

      setReduxData(data);
      setIsLoadData(false);
    };

    if (isEditAction) {
      setupAssemblyData();
    }
  }, [dispatch, graph, isEditAction, itemId]);

  useEffect(renderAssembliesData, [renderAssembliesData]);

  const items: TabsProps['items'] = [
    {
      key: 'engineering_drawing',
      label: 'Engineering Drawing',
      children: (
        <WizardEngineeringDrawing activeTabKey={activeTabKey} graph={graph} setGraph={setGraph} />
      ),
    },
    {
      key: 'drawing_notes',
      label: 'Drawing Notes',
      children: <WizardDwgNotes />,
    },
    {
      key: 'revisions',
      label: 'Revisions',
      children: <WizardRevisions />,
    },
    {
      key: 'title_block',
      label: 'Title Block',
      children: <WizardTitleBlock />,
    },
    {
      key: 'tabulated_data',
      label: 'Tabulated Data',
      children: <WizardTabulatedData />,
    },
    {
      key: 'bill_of_materials',
      label: 'Bill of Materials',
      children: <WizardBOM />,
    },
    {
      key: 'discussion',
      label: 'Discussion',
      children: <WizardDiscussion />,
    },
    {
      key: 'cable_test_card',
      label: 'Cable Test Card',
      children: <WizardCableTestCard />,
    },
  ];
  if (data?.whoami?.data?.id) {
    items.push({
      key: 'shared_With',
      label: 'Shared With',
      children: <WizardSharedWith activeTabKey={activeTabKey} />,
    });
  }

  if (isTenant) {
    items.push({
      key: 'approval_history',
      label: 'Approval History',
      children: <WizardApprovalHistory />,
    });
  } else {
    items.push({
      key: 'request_quote',
      label: 'Request Quote',
      children: <RequestQuote />,
    });
  }

  return (
    <div className={styles.layout}>
      <WizardHeader assemblyName="" graph={graph} />

      <Spin spinning={isLoading || isLoadData}>
        <div className={styles.content}>
          <div
            className={`${styles.toolDrawerLeft} ${!sideMenuBar.open && styles.toolDrawerClose}`}
          >
            <LeftSidebar graph={graph} />
          </div>
          <div className={`${styles.mainContent} ${!sideMenuBar.open && styles.mainContentFull}`}>
            <Tabs
              className={styles.tabAssemblies}
              type="card"
              onChange={handleTabChange}
              items={items}
            />
          </div>
          <div
            className={`${styles.toolDrawerRight} ${!sideMenuBar.open && styles.toolDrawerClose}`}
          >
            <RightSidebar graph={graph} />
          </div>
        </div>
      </Spin>
    </div>
  );
};

export default Wizard;
