import { Reducer } from 'redux';

import { WizardState } from './State';

import { initialWizardState } from '.';

import {
  WizardActionTypes,
  WizardAddTabulatedData,
  WizardLoadAssemblyData,
  WizardRemoveTabulatedData,
  WizardSetTabulatedDataPosition,
  WizardResetAssemblyData,
  WizardSetAssemblyID,
  WizardSetBillOfMaterialsData,
  WizardSetBillOfMaterialsPosition,
  WizardSetRevisionsData,
  WizardSetRevisionsPosition,
  WizardSetCustomerApprovalData,
  WizardSetCustomerApprovalsPosition,
  WizardAddDiscussionComment,
  WizardAddDiscussionTopic,
  WizardLoadDiscussions,
  WizardResetDrawingData,
  WizardSetDrawingNotesData,
  WizardSetDrawingNotesPosition,
  WizardSetIsLoading,
  WizardSetIsViewOnly,
  WizardSetLogoDimension,
  WizardSetNewTemplatePosition,
  WizardSetNewTitleBlockStyle,
  WizardSetOpenModalEditConnectorPin,
  WizardSetPaperSize,
  WizardSetTags,
  WizardSetTemplateLogo,
  WizardSetTemplateSVG,
  WizardSetTitleBlockData,
  WizardUpdatePaperSizeData,
  WizardSetDrawingNumber,
  WizardSetDrawingRevision,
  WizardSetCableTestCardData,
  WizardSetRequestedQuotesData,
  WizardUpdateAssemblyStatus,
  WizardUpdateAssemblyVisibility,
  WizardSetApprovalHistoriesTablePosition,
  WizardSetMenuViewActiveList,
  WizardSetAttachmentList,
} from '~/store/actions/wizard/Action';

const wizardRemoveTabulatedData = (
  action: WizardRemoveTabulatedData,
  state: WizardState,
): WizardState => {
  const { key } = action;
  const tabulatedData = { ...state.tabulatedData };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  tabulatedData.data = tabulatedData.data.filter((item: any) => item.key !== key);

  return {
    ...state,
    tabulatedData,
  };
};

const wizardAddDiscussionTopicReply = (
  action: WizardAddDiscussionComment,
  state: WizardState,
): WizardState => {
  const { topicId, reply } = action;
  const { discussions } = state;

  const newDiscussions = discussions.map((discussion) => {
    if (discussion.id === topicId) {
      discussion.replies = [...discussion.replies, reply];
    }

    return discussion;
  });

  return {
    ...state,
    discussions: newDiscussions,
  };
};

export const assemblyReducer: Reducer<
  WizardState,
  | WizardSetAssemblyID
  | WizardLoadAssemblyData
  | WizardSetBillOfMaterialsData
  | WizardSetBillOfMaterialsPosition
  | WizardAddTabulatedData
  | WizardRemoveTabulatedData
  | WizardSetTabulatedDataPosition
  | WizardSetRevisionsData
  | WizardSetRevisionsPosition
  | WizardSetCustomerApprovalData
  | WizardSetCustomerApprovalsPosition
  | WizardResetAssemblyData
  | WizardSetDrawingNotesData
  | WizardSetDrawingNotesPosition
  | WizardSetTitleBlockData
  | WizardSetTemplateSVG
  | WizardSetTemplateLogo
  | WizardSetTags
  | WizardSetPaperSize
  | WizardResetDrawingData
  | WizardAddDiscussionTopic
  | WizardAddDiscussionComment
  | WizardLoadDiscussions
  | WizardUpdatePaperSizeData
  | WizardSetNewTemplatePosition
  | WizardSetNewTitleBlockStyle
  | WizardSetOpenModalEditConnectorPin
  | WizardSetLogoDimension
  | WizardSetIsViewOnly
  | WizardSetIsLoading
  | WizardSetDrawingNumber
  | WizardSetDrawingRevision
  | WizardSetCableTestCardData
  | WizardSetRequestedQuotesData
  | WizardUpdateAssemblyStatus
  | WizardUpdateAssemblyVisibility
  | WizardSetApprovalHistoriesTablePosition
  | WizardSetMenuViewActiveList
  | WizardSetAttachmentList
> = (
  state: WizardState = initialWizardState,
  action:
    | WizardSetAssemblyID
    | WizardLoadAssemblyData
    | WizardSetBillOfMaterialsData
    | WizardSetBillOfMaterialsPosition
    | WizardAddTabulatedData
    | WizardRemoveTabulatedData
    | WizardSetTabulatedDataPosition
    | WizardSetRevisionsData
    | WizardSetRevisionsPosition
    | WizardSetCustomerApprovalData
    | WizardSetCustomerApprovalsPosition
    | WizardResetAssemblyData
    | WizardSetDrawingNotesData
    | WizardSetDrawingNotesPosition
    | WizardSetTitleBlockData
    | WizardSetTemplateSVG
    | WizardSetTemplateLogo
    | WizardSetTags
    | WizardSetPaperSize
    | WizardResetDrawingData
    | WizardAddDiscussionTopic
    | WizardAddDiscussionComment
    | WizardLoadDiscussions
    | WizardUpdatePaperSizeData
    | WizardSetNewTemplatePosition
    | WizardSetNewTitleBlockStyle
    | WizardSetOpenModalEditConnectorPin
    | WizardSetLogoDimension
    | WizardSetIsViewOnly
    | WizardSetIsLoading
    | WizardSetDrawingNumber
    | WizardSetDrawingRevision
    | WizardSetCableTestCardData
    | WizardSetRequestedQuotesData
    | WizardUpdateAssemblyStatus
    | WizardUpdateAssemblyVisibility
    | WizardSetApprovalHistoriesTablePosition
    | WizardSetMenuViewActiveList
    | WizardSetAttachmentList,
): WizardState => {
  switch (action.type) {
    case WizardActionTypes.WIZARD_SET_ASSEMBLY_ID:
      return {
        ...state,
        id: action.id,
      };
    case WizardActionTypes.WIZARD_LOAD_ASSEMBLY_DATA:
      return {
        ...state,
        id: action.assembly.id,
        name: action.assembly.name,
        guestEmailId: action.assembly.guest_email_id,
        status: action.assembly.status,
        reviewers: action.assembly.reviewers,
        isLoading: false,
        createdBy: action.assembly.created_by,
        isPublic: action.assembly.is_public,
      };
    case WizardActionTypes.WIZARD_UPDATE_ASSEMBLY_STATUS:
      return {
        ...state,
        status: action.status,
      };
    case WizardActionTypes.WIZARD_UPDATE_ASSEMBLY_Visibility:
      return {
        ...state,
        isPublic: action.isPublic,
      };
    case WizardActionTypes.WIZARD_SET_BILL_OF_MATERIALS_DATA:
      return {
        ...state,
        billOfMaterials: {
          data: action.billOfMaterialsData,
          position: state.billOfMaterials.position,
        },
      };
    case WizardActionTypes.WIZARD_SET_BILL_OF_MATERIALS_POSITION:
      return {
        ...state,
        billOfMaterials: {
          data: state.billOfMaterials.data,
          position: action.position,
        },
      };
    case WizardActionTypes.WIZARD_ADD_TABULATED_DATA:
      return {
        ...state,
        tabulatedData: {
          data: action.tabulatedData.data,
          columns: action.tabulatedData.columns,
          position: state.tabulatedData.position,
        },
      };
    case WizardActionTypes.WIZARD_REMOVE_TABULATED_DATA:
      return wizardRemoveTabulatedData(action, state);
    case WizardActionTypes.WIZARD_SET_TABULATED_DATA_POSITION:
      return {
        ...state,
        tabulatedData: {
          data: state.tabulatedData.data,
          columns: state.tabulatedData.columns,
          position: action.position,
        },
      };
    case WizardActionTypes.WIZARD_SET_REVISIONS_DATA:
      return {
        ...state,
        revisions: {
          data: action.data,
          position: state.revisions.position,
        },
      };
    case WizardActionTypes.WIZARD_SET_REVISIONS_POSITION:
      return {
        ...state,
        revisions: {
          data: state.revisions.data,
          position: action.position,
        },
      };
    case WizardActionTypes.WIZARD_SET_CUSTOMER_APPROVAL_DATA:
      return {
        ...state,
        customerApprovals: {
          data: action.data,
          position: state.customerApprovals.position,
        },
      };
    case WizardActionTypes.WIZARD_SET_CUSTOMER_APPROVALS_POSITION:
      return {
        ...state,
        customerApprovals: {
          data: state.customerApprovals.data,
          position: action.position,
        },
      };
    case WizardActionTypes.WIZARD_RESET_ASSEMBLY_DATA:
      return {
        ...initialWizardState,
      };
    case WizardActionTypes.WIZARD_SET_DRAWING_NOTES_DATA:
      return {
        ...state,
        drawingNotes: {
          data: action.data,
          position: state.drawingNotes.position,
        },
      };
    case WizardActionTypes.WIZARD_SET_DRAWING_NOTES_POSITION:
      return {
        ...state,
        drawingNotes: {
          data: state.drawingNotes.data,
          position: action.position,
        },
      };
    case WizardActionTypes.WIZARD_SET_TITLE_BLOCK_DATA:
      return {
        ...state,
        titleBlockData: action.data,
      };
    case WizardActionTypes.WIZARD_SET_TEMPLATE_SVG:
      return {
        ...state,
        templateSVG: action.svg,
      };
    case WizardActionTypes.WIZARD_SET_TEMPLATE_LOGO:
      return {
        ...state,
        templateLogo: action.logo,
      };
    case WizardActionTypes.WIZARD_SET_TAGS:
      return {
        ...state,
        tags: action.tags,
      };
    case WizardActionTypes.WIZARD_SET_PAPER_SIZE:
      return {
        ...state,
        paperSize: action.paperSize,
      };
    case WizardActionTypes.WIZARD_RESET_DRAWING_DATA:
      return {
        ...initialWizardState,
        id: state.id,
        name: state.name,
        guestEmailId: state.guestEmailId,
        tags: state.tags,
        templateSVG: state.templateSVG,
        templateLogo: state.templateLogo,
      };

    case WizardActionTypes.WIZARD_ADD_DISCUSSION_TOPIC:
      return {
        ...state,
        discussions: [...state.discussions, action.topic],
      };
    case WizardActionTypes.WIZARD_ADD_DISCUSSION_COMMENT:
      return wizardAddDiscussionTopicReply(action, state);
    case WizardActionTypes.WIZARD_LOAD_DISCUSSIONS:
      return {
        ...state,
        discussions: action.discussions,
      };
    case WizardActionTypes.WIZARD_UPDATE_PAPER_SIZE_DATA:
      return {
        ...state,
        paperSizeData: action.paperSizeData,
      };
    case WizardActionTypes.WIZARD_SET_NEW_TEMPLATE_POSITION:
      return {
        ...state,
        newTemplatePosition: action.newTemplate,
      };
    case WizardActionTypes.WIZARD_SET_NEW_TITLE_BLOCK_STYLE:
      return {
        ...state,
        newTitleBlockStyle: action.newTitleBlockStyle,
      };
    case WizardActionTypes.WIZARD_SET_OPEN_MODAL_EDIT_CONNECTOR_PIN:
      return {
        ...state,
        openModalEditConnectorPin: action.openModalEditConnectorPin,
      };
    case WizardActionTypes.WIZARD_SET_LOGO_DIMENSION:
      return {
        ...state,
        logoDimension: action.logoDimension,
      };
    case WizardActionTypes.WIZARD_SET_IS_VIEW_ONLY:
      return {
        ...state,
        isViewOnly: action.isViewOnly,
      };
    case WizardActionTypes.WIZARD_SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.isLoading,
      };
    case WizardActionTypes.WIZARD_SET_DRAWING_NUMBER:
      return {
        ...state,
        drawingNumber: action.drawingNumber,
      };
    case WizardActionTypes.WIZARD_SET_DRAWING_REVISION:
      return {
        ...state,
        drawingRevision: action.drawingRevision,
      };
    case WizardActionTypes.WIZARD_SET_CABLE_TEST_CARD_DATA:
      return {
        ...state,
        cableTestCardData: action.data,
      };
    case WizardActionTypes.WIZARD_SET_REQUESTED_QUOTES_DATA:
      return {
        ...state,
        requestedQuotes: action.data,
      };
    case WizardActionTypes.WIZARD_SET_APPROVAL_HISTORIES_TABLE_POSITION:
      return {
        ...state,
        approvalHistoriesTable: {
          position: action.position,
        },
      };
    case WizardActionTypes.WIZARD_SET_MENU_VIEW_ACTIVE_LIST:
      return {
        ...state,
        menuViewActiveList: action.data,
      };
    case WizardActionTypes.WIZARD_SET_ATTACHMENT_LIST:
      return {
        ...state,
        attchfile: action.data,
      };
    default:
      return state;
  }
};
