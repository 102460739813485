import { Reducer } from 'redux';

import { WizardState } from './State';
import { componentReducer } from './Component';
import { assemblyReducer } from './Assembly';

import {
  WizardActionTypes,
  WizardAddDiscussionComment,
  WizardAddDiscussionTopic,
  WizardAddTabulatedData,
  WizardLoadAssemblyData,
  WizardLoadDiscussions,
  WizardRemoveTabulatedData,
  WizardResetDrawingData,
  WizardSetAssemblyID,
  WizardSetBillOfMaterialsData,
  WizardSetIsViewOnly,
  WizardSetLogoDimension,
  WizardSetNewTitleBlockStyle,
  WizardSetOpenModalEditConnectorPin,
  WizardSetPaperSize,
  WizardSetRevisionsData,
  WizardSetRevisionsPosition,
  WizardSetCustomerApprovalData,
  WizardSetCustomerApprovalsPosition,
  WizardSetTemplateLogo,
  WizardSetTemplateSVG,
  WizardSetTitleBlockData,
  WizardUpdatePaperSizeData,
  WizardSetNewTemplatePosition,
  WizardSetTags,
  WizardSetDrawingNotesData,
  WizardSetDrawingNotesPosition,
  WizardSetTabulatedDataPosition,
  WizardSetBillOfMaterialsPosition,
  WizardResetAssemblyData,
  WizardSetIsLoading,
  WizardSetDrawingNumber,
  WizardSetDrawingRevision,
  WizardSetCableTestCardData,
  WizardSetRequestedQuotesData,
  WizardSetApprovalHistoriesTablePosition,
  WizardSetMenuViewActiveList,
  WizardSetAttachmentList,
} from '~/store/actions/wizard/Action';
import {
  WizardAddComponent,
  WizardRemoveComponent,
  WizardUpdateComponentName,
  WizardUpdateComponentCableLength,
  WizardComponentTypes,
  WizardSetComponentsData,
  WizardAddHiddenComponent,
  WizardRemoveHiddenComponent,
  WizardLoadHiddenComponents,
  WizardSetDBComponentsData,
  WizardSetShowComponentControl,
  WizardSetShowWireControl,
} from '~/store/actions/wizard/Component';
import { paperSizeData } from '~/utils/paperSize';

export const initialWizardState: WizardState = {
  id: undefined,
  name: '',
  guestEmailId: '',
  status: 'Draft',
  reviewers: null,
  createdBy: undefined,
  tags: [],
  wizardType: 'engineeringDrawing',
  components: {},
  dbComponents: {},
  options: {
    engineeringDrawing: {
      width: 240,
      height: 80,
      position: {
        x: 0,
        y: 0,
      },
      angle: 0,
      mirror: false,
    },
    schematics: {
      width: 40,
      height: 40,
      position: {
        x: 0,
        y: 0,
      },
      angle: 0,
      mirror: false,
    },
  },
  highlightedConnector: undefined,
  billOfMaterials: {
    data: [],
    position: {
      x: 495,
      y: 50,
    },
  },
  tabulatedData: {
    data: [],
    columns: [],
    position: {
      x: 40,
      y: 50,
    },
  },
  revisions: {
    data: [],
    position: {
      x: 480,
      y: 50,
    },
  },
  customerApprovals: {
    data: [],
    position: {
      x: 485,
      y: 60,
    },
  },
  drawingNotes: {
    data: [],
    position: {
      x: 40,
      y: 50,
    },
  },
  titleBlockData: [
    {
      key: 'assemblyDescription',
      fieldName: 'Assembly Description',
      fieldValue: '',
    },
    {
      key: 'assemblyDrawingNumber',
      fieldName: 'Assembly Drawing Number',
      fieldValue: '',
    },
    {
      key: 'assemblyRevision',
      fieldName: 'Assembly Revision',
      fieldValue: '',
    },
    {
      key: 'assemblyDesignState',
      fieldName: 'Assembly Design State',
      fieldValue: '',
    },
    {
      key: 'customerPN',
      fieldName: 'Customer P/N',
      fieldValue: '',
    },
    {
      key: 'project',
      fieldName: 'Project',
      fieldValue: '',
    },
    {
      key: 'subject',
      fieldName: 'Subject',
      fieldValue: '',
    },
    {
      key: 'designer',
      fieldName: 'Designer',
      fieldValue: '',
    },
    {
      key: 'designDate',
      fieldName: 'Design Date',
      fieldValue: '',
    },
    {
      key: 'checkedBy',
      fieldName: 'Checked By',
      fieldValue: '',
    },
    {
      key: 'checkedDate',
      fieldName: 'Checked Date',
      fieldValue: '',
    },
    {
      key: 'approvedBy',
      fieldName: 'Approved By',
      fieldValue: '',
    },
    {
      key: 'approvedDate',
      fieldName: 'Approved Date',
      fieldValue: '',
    },
  ],
  paperSize: 'a4',
  hiddenComponents: [],
  discussions: [],
  paperSizeData,
  newTemplatePosition: null,
  newTitleBlockStyle: null,
  openModalEditConnectorPin: false,
  logoDimension: {
    width: 0,
    height: 0,
  },
  isViewOnly: false,
  templateSVG: '',
  templateLogo: '',
  isLoading: false,
  isPublic: true,
  drawingNumber: '',
  drawingRevision: '',
  showComponentControl: false,
  showWireControl: false,
  cableTestCardData: null,
  requestedQuotes: [],
  approvalHistoriesTable: {
    position: {
      x: 50,
      y: 50,
    },
  },
  menuViewActiveList: [
    'template',
    'logo',
    'grid',
    'status-watermark',
    'bom',
    'approval-histories',
    'disabled-all',
  ],
  attchfile: [],
};

export const wizardReducer: Reducer<
  WizardState,
  | WizardSetAssemblyID
  | WizardLoadAssemblyData
  | WizardAddComponent
  | WizardUpdateComponentName
  | WizardUpdateComponentCableLength
  | WizardRemoveComponent
  | WizardSetBillOfMaterialsData
  | WizardSetBillOfMaterialsPosition
  | WizardAddTabulatedData
  | WizardRemoveTabulatedData
  | WizardSetTabulatedDataPosition
  | WizardSetRevisionsData
  | WizardSetRevisionsPosition
  | WizardSetCustomerApprovalData
  | WizardSetCustomerApprovalsPosition
  | WizardSetComponentsData
  | WizardResetAssemblyData
  | WizardSetDrawingNotesData
  | WizardSetDrawingNotesPosition
  | WizardSetTitleBlockData
  | WizardSetTemplateSVG
  | WizardSetTemplateLogo
  | WizardSetTags
  | WizardSetPaperSize
  | WizardResetDrawingData
  | WizardAddHiddenComponent
  | WizardRemoveHiddenComponent
  | WizardLoadHiddenComponents
  | WizardAddDiscussionTopic
  | WizardAddDiscussionComment
  | WizardLoadDiscussions
  | WizardSetDBComponentsData
  | WizardUpdatePaperSizeData
  | WizardSetNewTemplatePosition
  | WizardSetNewTitleBlockStyle
  | WizardSetOpenModalEditConnectorPin
  | WizardSetLogoDimension
  | WizardSetIsViewOnly
  | WizardSetIsLoading
  | WizardSetDrawingNumber
  | WizardSetDrawingRevision
  | WizardSetShowComponentControl
  | WizardSetShowWireControl
  | WizardSetCableTestCardData
  | WizardSetRequestedQuotesData
  | WizardSetApprovalHistoriesTablePosition
  | WizardSetMenuViewActiveList
  | WizardSetAttachmentList
> = (
  state: WizardState = initialWizardState,
  action:
    | WizardSetAssemblyID
    | WizardLoadAssemblyData
    | WizardAddComponent
    | WizardUpdateComponentName
    | WizardUpdateComponentCableLength
    | WizardRemoveComponent
    | WizardSetBillOfMaterialsData
    | WizardSetBillOfMaterialsPosition
    | WizardAddTabulatedData
    | WizardRemoveTabulatedData
    | WizardSetTabulatedDataPosition
    | WizardSetRevisionsData
    | WizardSetRevisionsPosition
    | WizardSetCustomerApprovalData
    | WizardSetCustomerApprovalsPosition
    | WizardSetComponentsData
    | WizardResetAssemblyData
    | WizardSetDrawingNotesData
    | WizardSetDrawingNotesPosition
    | WizardSetTitleBlockData
    | WizardSetTemplateSVG
    | WizardSetTemplateLogo
    | WizardSetTags
    | WizardSetPaperSize
    | WizardResetDrawingData
    | WizardAddHiddenComponent
    | WizardRemoveHiddenComponent
    | WizardLoadHiddenComponents
    | WizardAddDiscussionTopic
    | WizardAddDiscussionComment
    | WizardLoadDiscussions
    | WizardSetDBComponentsData
    | WizardUpdatePaperSizeData
    | WizardSetNewTemplatePosition
    | WizardSetNewTitleBlockStyle
    | WizardSetOpenModalEditConnectorPin
    | WizardSetLogoDimension
    | WizardSetIsViewOnly
    | WizardSetIsLoading
    | WizardSetDrawingNumber
    | WizardSetDrawingRevision
    | WizardSetShowComponentControl
    | WizardSetShowWireControl
    | WizardSetCableTestCardData
    | WizardSetRequestedQuotesData
    | WizardSetApprovalHistoriesTablePosition
    | WizardSetMenuViewActiveList
    | WizardSetAttachmentList,
): WizardState => {
  switch (action.type) {
    case WizardComponentTypes.WIZARD_ADD_COMPONENT:
    case WizardComponentTypes.WIZARD_UPDATE_COMPONENT_NAME:
    case WizardComponentTypes.WIZARD_UPDATE_COMPONENT_CABLE_LENGTH:
    case WizardComponentTypes.WIZARD_REMOVE_COMPONENT:
    case WizardComponentTypes.WIZARD_SET_COMPONENTS_DATA:
    case WizardComponentTypes.WIZARD_ADD_HIDDEN_COMPONENT:
    case WizardComponentTypes.WIZARD_REMOVE_HIDDEN_COMPONENT:
    case WizardComponentTypes.WIZARD_LOAD_HIDDEN_COMPONENTS:
    case WizardComponentTypes.WIZARD_SET_DB_COMPONENTS_DATA:
    case WizardComponentTypes.WIZARD_SET_SHOW_COMPONENT_CONTROL:
    case WizardComponentTypes.WIZARD_SET_SHOW_WIRE_CONTROL:
      return componentReducer(state, action);

    case WizardActionTypes.WIZARD_SET_ASSEMBLY_ID:
    case WizardActionTypes.WIZARD_LOAD_ASSEMBLY_DATA:
    case WizardActionTypes.WIZARD_SET_BILL_OF_MATERIALS_DATA:
    case WizardActionTypes.WIZARD_SET_BILL_OF_MATERIALS_POSITION:
    case WizardActionTypes.WIZARD_ADD_TABULATED_DATA:
    case WizardActionTypes.WIZARD_REMOVE_TABULATED_DATA:
    case WizardActionTypes.WIZARD_SET_TABULATED_DATA_POSITION:
    case WizardActionTypes.WIZARD_SET_REVISIONS_DATA:
    case WizardActionTypes.WIZARD_SET_REVISIONS_POSITION:
    case WizardActionTypes.WIZARD_SET_CUSTOMER_APPROVAL_DATA:
    case WizardActionTypes.WIZARD_SET_CUSTOMER_APPROVALS_POSITION:
    case WizardActionTypes.WIZARD_RESET_ASSEMBLY_DATA:
    case WizardActionTypes.WIZARD_SET_DRAWING_NOTES_DATA:
    case WizardActionTypes.WIZARD_SET_DRAWING_NOTES_POSITION:
    case WizardActionTypes.WIZARD_SET_TITLE_BLOCK_DATA:
    case WizardActionTypes.WIZARD_SET_TEMPLATE_SVG:
    case WizardActionTypes.WIZARD_SET_TEMPLATE_LOGO:
    case WizardActionTypes.WIZARD_SET_TAGS:
    case WizardActionTypes.WIZARD_SET_PAPER_SIZE:
    case WizardActionTypes.WIZARD_RESET_DRAWING_DATA:
    case WizardActionTypes.WIZARD_ADD_DISCUSSION_TOPIC:
    case WizardActionTypes.WIZARD_ADD_DISCUSSION_COMMENT:
    case WizardActionTypes.WIZARD_LOAD_DISCUSSIONS:
    case WizardActionTypes.WIZARD_UPDATE_PAPER_SIZE_DATA:
    case WizardActionTypes.WIZARD_SET_NEW_TEMPLATE_POSITION:
    case WizardActionTypes.WIZARD_SET_NEW_TITLE_BLOCK_STYLE:
    case WizardActionTypes.WIZARD_SET_OPEN_MODAL_EDIT_CONNECTOR_PIN:
    case WizardActionTypes.WIZARD_SET_LOGO_DIMENSION:
    case WizardActionTypes.WIZARD_SET_IS_VIEW_ONLY:
    case WizardActionTypes.WIZARD_SET_IS_LOADING:
    case WizardActionTypes.WIZARD_SET_DRAWING_NUMBER:
    case WizardActionTypes.WIZARD_SET_DRAWING_REVISION:
    case WizardActionTypes.WIZARD_SET_CABLE_TEST_CARD_DATA:
    case WizardActionTypes.WIZARD_SET_REQUESTED_QUOTES_DATA:
    case WizardActionTypes.WIZARD_SET_APPROVAL_HISTORIES_TABLE_POSITION:
    case WizardActionTypes.WIZARD_SET_MENU_VIEW_ACTIVE_LIST:
    case WizardActionTypes.WIZARD_SET_ATTACHMENT_LIST:
      return assemblyReducer(state, action);

    default:
      return state;
  }
};
